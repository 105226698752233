import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    search:
    {
        width: 300,
        marginTop: 20,
        color: '#00acc1'
    },
    searchIcon:
    {
        color: '#00acc1',
        fontSize: '2em'
    },
    root: {
        maxWidth: 300,
    },
    card: {
        margin: 15,
        backgroundColor: '#ffffff'
    },
    title:
    {
        fontSize: '1em',
        textAlign: 'center',
        color: '#000',
        fontWeight: 400,
        letterSpacing: '0.01071em',
        opacity: 0.54
    },
    price: {
        color: 'orange',
    },
    links: {
        color: '#00acc1',
        fontWeight: 'bold',
        fontSize: '0.9em',
        "&:hover": {
            boxShadow:
            "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)",
            backgroundColor: "#00acc1",
            color: "#fff"
        }
    },
    cardActions:{
        float: 'right'
    },
    resultsText:{
        fontSize: '12px',
        color: '#9c27b0'
    }
});

export default function InfoCard(props)
{

    const classes = makeStyles(useStyles);

    const [expanded, setExpanded] = useState(false);
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        if (localStorage.getItem(props.key) === 'true')
        {
            setClosed(true);
        }
    }, []);

    function closeCard(e)
    {
        setClosed(true);
        localStorage.setItem(props.key, true);
    }

    function showMore()
    {
        setExpanded(true);
    }

    return (
        <>
            {!closed && !expanded && 
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent>
                            <div className={classes.title}>
                                You are viewing products we have previously made. You can purchase these directly or customize them to your liking.
                            </div>
                        </CardContent>
                    </CardActionArea>
                    <CardActions className={classes.cardActions} >
                        <Button size="small" className={classes.links} onClick={showMore}>
                            Tell me more
                        </Button>
                        <Button size="small" className={classes.links} onClick={closeCard}>
                            Thanks, I got it
                        </Button>
                    </CardActions>
                </Card>
            }
            {expanded && !closed && 
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardContent>
                            <div className={classes.title}>
                                You are viewing products we have previously made. You can purchase these directly or customize them to your liking.
                            </div>
                            <p>This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. 
                            This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. 
                            </p>
                            <p>This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. 
                            This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. 
                            </p>
                            <p>This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. 
                            This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. This is how it works. 
                            </p>
                        </CardContent>
                    </CardActionArea>
                    <CardActions className={classes.cardActions} >
                        <Button size="small" className={classes.links} onClick={closeCard}>
                            Close
                        </Button>
                    </CardActions>
                </Card>
            }
        </>
    )
}