export const STORE_SELECTIONS = 'STORE_SELECTIONS';
export const GET_SELECTIONS = 'GET_SELECTIONS';

export const actionCreators = {
    storeSelections: (selections) => async (dispatch, getState) => {
        dispatch({type: GET_SELECTIONS, selections: selections });
    },
    getSelections: () => async (dispatch, getState) => {
        dispatch({type: GET_SELECTIONS, selections: getState().customizeReducer.selections });
    }
}

