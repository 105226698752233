import React from 'react';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function About(props)
{

    const classes = useStyles();
    
    return (
        <div className='marginTop70'>
            <GridContainer xs={10}>
            <div className={classes.typo}>
                    <div className={classes.title}>
                        <h2>About Us</h2>
                    </div>

                    <p>When you want a unique, creative and special cake topper, AsiaWorld is the great place for you. With passion, talent and over 10 year experience in handicraft, AsiaWorld designs the most unique and cutest wedding cake topper and miniature you have ever seen.</p>
                    <p>AsiaWorld clay figurine is perfect wedding cake topper or birthday cake topper to display for years. It is also meaningful keepsake gift for any special occasion as wedding, birthday, engagement, anniversary, graduation, baby shower, propose, etc. AsiaWorld also creates clay miniature for your important business partner/customer/employee as VIP presents, awards or door gifts.</p>
                    <p>These personalized wedding cake toppers and clay figurines are made from customer’s images and idea so each one is handmade carefully with love. Clay is combined with different elements as fabric, wood</p>
                    <p>AsiaWorld topper would like to honor you just the way you are through these clay figurines – they are as unique as you are. AsiaWorld topper aslo want to help you express your love with your loved ones, your friends, family or your business image in an unforgettable way with these lovely and impressive clay miniatures.</p>
                    
                </div>
             </GridContainer>
        </div>
        )
}