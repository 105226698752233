import React, { useState, useEffect } from 'react';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../_actions/customizeActions';
import  { GithubPicker } from 'react-color';
import Button from '../CustomButtons/Button';
import { makeStyles } from "@material-ui/core/styles";
import CustomDropdown from '../CustomDropdown/CustomDropdown';

const styles = makeStyles({
    popup: {
        marginTop: 0
    },
    buttonPicker: {
        marginRight: 20
    },
    title: {
        fontSize: 16
    },
    additionalDetails: {
        width: 300,
        height: 125
    }
})

function ProductCustomize(props)
{

    const { getSelections } = props;
    const { storeSelections } = props;

    //selected states
    const [eyeColor, setEyeColor] = useState(props.selections.eyeColor);
    const [hairColor, setHairColor] = useState(props.selections.hairColor);
    const [skinColor, setSkinColor] = useState(props.selections.skinColor);
    const [eyeType, setEyeType] = useState(props.selections.eyeType);
    const [eyeTypeSelection, setEyeTypeSelection] = useState(props.selections.eyeType !== undefined ? props.selections.eyeType : 'Eye Style');
    const [eyeTypeColor, setEyeTypeColor] = useState(props.selections.eyeType !== undefined ? '#00acc1' : '');
    const [additionalDetails, setAdditionalDetails] = useState(props.selections.details);
    
    useEffect(() => {
        getSelections();
    }, []);

    useEffect(() => {
        storeSelections( {eyeColor: eyeColor, hairColor: hairColor, skinColor: skinColor, eyeType: eyeTypeSelection, details: additionalDetails})
    }, [eyeColor, hairColor, skinColor, eyeType, additionalDetails]);

    const classes = styles();
   
    //colors
    const eyeColors = ['#d3c59e', '#58483a', '#7099cb', '#82aac0', '#bdcfa7', '#af9c74', '#4e4655', '#b6b1ac', '#43423d'];
    const hairColors = ['#d5c4a2', '#dccca3', '#c67d36', '#502629', '#948786', '#c0b5af', '#956d4d', '#5d433d', '#59442d', '#383b34'];
    const skinColors = ['#f1e0c3','#fedcc1','#e7ba9b','#c19873','#7c6250','#69584b'];
    const eyeTypes = [
        {text: 'Cute Small Dots', selected: false},
        {text: 'Eyes Drawn', selected: false}
    ]

    //toggle state
    const [showEyeColors, setShowEyeColors] = useState(false);
    const [showHairColors, setShowHairColors] = useState(false);
    const [showSkinColors, setShowSkinColors] = useState(false);

    //Toggles
    const toggleEyeColors = () => {
        setShowEyeColors(!showEyeColors);
    }

    const toggleHairColors = () => {
        setShowHairColors(!showHairColors);
    }

    const toggleSkinColors = () => {
        setShowSkinColors(!showSkinColors);
    }

    //Selected Events
    const eyeColorChanged = (e) => {
        setEyeColor(e.hex);
        toggleEyeColors();
    }

    const hairColorChanged = (e) => {
        setHairColor(e.hex);
        toggleHairColors();
    }

    const skinColorChanged = (e) => {
        setSkinColor(e.hex);
        toggleSkinColors();
    }

    const eyeTypeClick = (e) => {
        setEyeType(e);
        setEyeTypeSelection(e);
        setEyeTypeColor('#00acc1');
    }

    const handleDetailsChange = (e) => {
        setAdditionalDetails(e.target.value);
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12}>
                    <div className={classes.title}>Eye Style</div>
                    <CustomDropdown buttonText={eyeTypeSelection} dropdownList={eyeTypes} onClick={eyeTypeClick} backgroundColor={eyeTypeColor} />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <div className={classes.title}>Colors</div>
                </GridItem>
                <GridItem xs={2}>
                    <Button styles={{backgroundColor: eyeColor}} onClick={toggleEyeColors} className={classes.buttonPicker}>Eye Color</Button>
                    {showEyeColors && 
                        <GithubPicker width={140} colors={eyeColors} className={classes.popup} triangle='hide' onChangeComplete={eyeColorChanged} />
                    }
                </GridItem>
                <GridItem xs={2}>
                    <Button styles={{backgroundColor: hairColor}} onClick={toggleHairColors} className={classes.buttonPicker}>Hair Color</Button>
                    {showHairColors && 
                        <GithubPicker width={140} colors={hairColors} className={classes.popup} triangle='hide' onChangeComplete={hairColorChanged} />
                    }
                </GridItem>            
                <GridItem xs={2}>
                    <Button styles={{backgroundColor: skinColor}} onClick={toggleSkinColors} className={classes.buttonPicker}>Skin Color</Button>
                    {showSkinColors && 
                        <GithubPicker width={140} colors={skinColors} className={classes.popup} triangle='hide' onChangeComplete={skinColorChanged} />
                    }
                </GridItem>              
            </GridContainer>
            <GridContainer xs={12}>
                <GridItem xs={12}>
                    <div className={classes.title}>Additional Details</div>
                    <textarea className={classes.additionalDetails} value={additionalDetails} onChange={handleDetailsChange}></textarea>
                </GridItem>                
                <GridItem xs={2}>
                    {/* <Button color='info' onClick={props.openPurchase}>Purchase</Button> */}
                </GridItem>
            </GridContainer>
        </>
    )
}

const mapStateToProps = state => {
    return {
        selections: state.customizeReducer.selections,
    }
}

const mapDispatchToProps = (dispatch) => 
    bindActionCreators({...actionCreators}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductCustomize);