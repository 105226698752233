import React, { useEffect } from 'react';
import {logout} from './../_services/user.services';

export default function Logout(props)
{

    useEffect(() => {
        logout();
        window.location.replace('/')
    }, []);

    return (
        <div>
            Logging you out
        </div>
    )
}