import React, { useEffect, useState } from 'react';
import {login, loginFacebook} from './../_services/user.services';
import Card from './../components/card/Card';
import CardHeader from './../components/card/CardHeader';
import CardFooter from './../components/card/CardFooter';
import Button from "./../components/CustomButtons/Button.js";
import CardBody from "./../components/card/CardBody.js";
import CustomInput from "./../components/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import loginStyles from './login.module.css';
import GridContainer from "components/grid/GridContainer.js";
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import GridItem from "components/grid/GridItem.js";
import FacebookLogin from 'react-facebook-login';

const useStyles = makeStyles(styles);

export default function Login(props)
{
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function() {
      setCardAnimation("");
    }, 700);
    const classes = useStyles();
  
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loginError, setLoginError] = useState(false);

    const handleLogin = () =>
    {
        (async () => {
            try{
                await login(email, password);
                if (localStorage.getItem('user') !== undefined)
                {
                    window.location.replace('/')
                }    
            }catch{
                setLoginError(true);
            }
        })();
    }

    const responseFacebook = async (response) => {
        await loginFacebook(response.email, response.name, response.id);
        if (localStorage.getItem('user') !== undefined)
        {
            window.location.replace('/');
        }
    }

    const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_ID;

    return (
        <div className="marginTop150">
            <Container maxWidth='xl'>
                <GridContainer justify="center">
                    <GridItem xs={9} sm={8} md={4} lg={3}>
                        <Card className={classes[cardAnimaton]}>
                            <form className={classes.form}>
                            <CardHeader color="gray" className={classes.cardHeader}>
                                <h4>Login</h4>
                                <div className={classes.socialLine}>
                                <FacebookLogin
                                    appId={FACEBOOK_APP_ID}
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    icon="fab fa-facebook"
                                    cssClass={loginStyles.btnFacebook}
                                    callback={responseFacebook} />
                                </div>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                labelText="Email..."
                                id="email"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "email",
                                    onChange: (e) => setEmail(e.target.value),
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Email className={classes.inputIconsColor} />
                                    </InputAdornment>
                                    )
                                }}
                                />
                                <CustomInput
                                labelText="Password"
                                id="pass"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "password",
                                    onChange: (e) => setPassword(e.target.value),
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputIconsColor}>
                                        lock_outline
                                        </Icon>
                                    </InputAdornment>
                                    ),
                                    autoComplete: "off"
                                }}
                                />
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>
                                <Button simple color="info" size="lg" onClick={handleLogin}>
                                Login
                                </Button>
                            </CardFooter>
                            {loginError && 
                                <CardFooter className={classes.cardFooter} style={{color: 'red'}}>
                                    Incorrect Login
                                </CardFooter>
                            }
                            </form>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{marginBottom: '100px', textAlign: 'center'}}>
                        Don't have an account? <Link to='/register'>Create one!</Link>
                    </GridItem>
                </GridContainer>
            </Container>
       </div>
    )
}