import React from "react";
import Image1 from "../../img/sliderimages/slider4.jpg";
import GridContainer from "../../components/grid/GridContainer";
import GridItem from "../../components/grid/GridItem";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";
import ContactUsSection from "./sections/ContactUsSection";
import ProductSection from "./sections/ProductSection";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import FormatQuote from "@material-ui/icons/FormatQuote";
import image from "assets/img/faces/card-profile1-square.jpg";
import styles from "assets/jss/material-kit-react/views/components.js";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function Home(props) {
  const classes = useStyles();

  return (
    <div>
      <Parallax image={Image1}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Asia World Topper</h1>
                <div>
                  <Button color="gray" size="lg" round>
                    Customize Yours Today
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer
              className={classes.textCenter}
              justify="center"
              xs={12}
            >
              <GridItem xs={12} sm={12} md={8}>
                <h2>Wedding Cake Toppers</h2>
                <h4>
                  We make fantastic, customized wedding cake toppers for your
                  special day. Visit our product section to browse the hundreds
                  of pre-made wedding cake toppers that can be yours today.
                </h4>
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <Button color="info" size="lg" href="/products">
                  Pre-made Toppers
                </Button>
                <Button color="info" size="lg" href="/customize">
                  Custom Toppers
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <GridContainer className={classes.textCenter} justify="center" xs={12}>
          <GridItem xs={12} sm={12} md={8}>
            <ProductSection />
            <ContactUsSection />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
