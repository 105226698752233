import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import productsReducer from './productsReducer';
import customizeReducer from './customizeReducer';
import purchaseReducer from './purchaseReducer';
import adminReducer from './adminReducer';


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    productsReducer,
    customizeReducer,
    purchaseReducer,
    adminReducer
})

export default createRootReducer;