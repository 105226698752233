import React from 'react';
import Button from '../CustomButtons/Button';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';

export default function ProductOverview(props)
{
    return (
        <>
        <GridContainer xs={12}>
            <GridItem xs={9}>
                <div dangerouslySetInnerHTML={{
                    __html: props.product.shortDescription
                }}>
                </div>
            </GridItem>
            <GridItem>
                <div className='marginTop15'>
                    {/* <Button color='info' onClick={props.openCustomize}>Customize</Button>
                    <Button color='info' onClick={props.openPurchase}>Purchase ${props.product.price}</Button> */}
                </div>
            </GridItem>
        </GridContainer>
        </>
    )
}