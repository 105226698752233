import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../_actions/purchaseActions';
import { Link } from 'react-router-dom';
import user from '../../_helpers/user.js';
import Button from '../CustomButtons/Button';

const STRIPE_ID = process.env.REACT_APP_STRIPE_ID;
const stripePromise = loadStripe(STRIPE_ID);

function ProductPurchase(props)
{
    const redirectToStripe = async (event) => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({sessionId: props.sessionid});
    }

    useEffect(() => {
        props.requestSession(props.product.id);
    }, [])

    useEffect(() => {
        // if (typeof props.sessionid == 'string')
        // {
        //     redirectToStripe();
        // }
    }, [props.sessionid]);

    const handleClick = () => {
        props.requestSession(props.product.id);
    }

    return (
        <>
            {(props.selections.eyeColor !== null || props.selections.hairColor !== null || props.selections.skinColor !== null || props.selections.eyeType !== null) &&
                <div>
                    <span style={{fontWeight: 'bold'}}>Confirm your customizations</span><br/>
                    Eye Color: {props.selections.eyeColor}<br/>
                    Skin Color: {props.selections.skinColor}<br/>
                    Hair Color: {props.selections.hairColor}<br/>
                    Eye Type: {props.selections.eyeType}<br/>
                </div>
            }
            {props.selections.length === 0 && 
                <div>No customizations</div>
            }
            {user === null && 
                <div className="marginTop15">Please <Link to='/login'>Login</Link> or <Link to='/register'>create an account</Link> to purchase our products.</div>
            }
            {user !== null && 
                <Button color='primary' onClick={() => redirectToStripe()}>Purchase (With Stripe)</Button>
            }
        </>
        
    )
}

const mapStateToProps = state => {
    return {
        sessionid: state.purchaseReducer.sessionid,
        selections: state.customizeReducer.selections,
    }
}

const mapDispatchToProps = (dispatch) => 
    bindActionCreators({...actionCreators}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductPurchase);