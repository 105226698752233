import Swagger from 'swagger-client';

export async function login(email, password)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ email, password })
    }
    const BASEURL = process.env.REACT_APP_API_URL;

    await fetch(BASEURL + '/account/authenticate', requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
        });
}

export async function loginFacebook(email, facebookName, facebookUserId)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ email, facebookName, facebookUserId })
    }
    const BASEURL = process.env.REACT_APP_API_URL;

    await fetch(BASEURL + '/account/facebook', requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
        });
}

export async function register(email, password, firstname, lastname)
{

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ email, password, firstname, lastname })
    }
    const BASEURL = process.env.REACT_APP_API_URL;

    return fetch(BASEURL + '/account/register', requestOptions)
        .then(handleResponse)
        .then(user => {
            return true;
        });
}

export function logout()
{
    localStorage.removeItem('user');
}

function handleResponse(response)
{
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if(!response.ok)
        {
            if (response.status === 401)
            {
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    })
}