import React from 'react';

export default class ErrorBoundary extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error)
    {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo)
    {
        //log error
    }

    render()
    {
        if (this.state.hasError)
        {
            return <h1 className='marginTop70'>Something went wrong</h1>;
        }
        return this.props.children;
    }
}

