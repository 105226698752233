import {
    REQUEST_SESSION, RECEIVE_SESSION
} from '../_actions/purchaseActions.js';

const initialState = {
    sessionid: {}
}

export default function productsReducer(state, action)
{
    state = state || initialState;

     switch (action.type)
    {
        case REQUEST_SESSION:
            return {
                ...state,
                sessionid: {}
            };
        case RECEIVE_SESSION:
            return {
                ...state,
                sessionid: action.sessionid
            };
        default:
            return state;
    }
}
