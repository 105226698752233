import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./views/home/home.js";
import TopBanner from "./components/common/topBanner.js";
import Listing from "./views/products/listing.js";
import Details from "./views/products/details.js";
import { hot } from "react-hot-loader/root";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import Login from "./views/login";
import Logout from "./views/logout";
import Register from "./views/register";
//import Admin from './views/admin/admin';
import Footer from "components/Footer/Footer.js";
import Loading from "./components/common/loading";
import ReactGA from "react-ga4";

import "../src/assets/css/material-kit-react.css";
import ErrorBoundary from "components/error/ErrorBoundary.js";
import HowItWorks from "views/pages/howItWorks.js";
import TermsConditions from "views/pages/terms-and-conditions.js";
import Privacy from "views/pages/privacy.js";
import About from "views/pages/about.js";
import Shipping from "views/pages/shipping.js";
import Contact from "views/pages/contact.js";

const Admin = lazy(() => import("./views/admin/admin"));

function initializeReactGA() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
  // ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  useEffect(() => {
    initializeReactGA();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <TopBanner />
        <ErrorBoundary>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/how-it-works" exact component={HowItWorks} />
            <Route
              path="/terms-and-conditions"
              exact
              component={TermsConditions}
            />
            <Route path="/about" exact component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/shipping" exact component={Shipping} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/products" exact component={Listing} />
            <Route path="/products/:id" component={Details} />
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/logout" exact component={Logout} />
            <PrivateRoute path="/productstest" exact component={Listing} />
            <Suspense fallback={<Loading />}>
              <AdminRoute path="/admin" exact component={Admin} />
            </Suspense>
          </Switch>
          <Footer />
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App;
