import React from 'react';
import logo from '../../img/logo.png';
import { Link } from 'react-router-dom';

export default function HeaderLogo()
{
    return(
        <>
        <header className="App-header">
        <Link to={'/'}>
            <img src={logo} className="smallLogo" alt="logo" />
        </Link>
        </header>
        </>
    )
}
