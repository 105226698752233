import {
    RECEIVE_PRODUCTS, REQUEST_PRODUCTS, RECEIVE_PRODUCT_COUNT,  RECEIVE_ALL_PRODUCTS, RECEIVE_PRODUCT, REQUEST_SEARCH_RESULTS, RECEIVE_SEARCH_RESULTS
    , RECEIVE_CATEGORIES, REQUEST_CATEGORIES, REQUEST_PRODUCT_UPDATE, RECEIVE_PRODUCT_UPDATE
} from '../_actions/actions.js';

const initialState = {
    products: [], searchResults: [], categories: [], isLoading: true, isError: false, product: {}, current_page: 1, current_pagesize: '20', current_offset: 0, current_sort: '3', current_sort_dir: 'asc'
}

export default function productsReducer(state, action)
{
    state = state || initialState;

     switch (action.type)
    {
        case REQUEST_PRODUCTS:
            return {
                ...state,
                products: [],
                isProductsLoading: true,
                isError: false
            };
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                products: action.products,
                current_offset: action.current_offset,
                current_pagesize: action.current_pagesize,
                current_page: action.current_page,
                current_sort_dir: action.current_sort_dir,
                current_sort: action.current_sort,
                product_count: action.product_count,
                isProductsLoading: false,
                isError: false
            };
        case RECEIVE_PRODUCT:
            return {
                ...state,
                isLoading: false,
                isError: false,
                product: action.product
            };
        case RECEIVE_ALL_PRODUCTS:
            return {
                ...state,
                products: action.products,
                isProductsLoading: false,
                isError: false
                };            
        case RECEIVE_PRODUCT_COUNT:
            return {
                ...state,
                product_count: action.product_count
            };            
        case REQUEST_SEARCH_RESULTS:
            return {
                ...state,
                isProductsLoading: true,
                isError: false,
                searchResults: [],
                products: [],
                searchTerm: action.searchTerm
            };
        case RECEIVE_SEARCH_RESULTS:
            return {
                ...state,
                isProductsLoading: false,
                isError: false,
                searchResults: action.searchResults,
                searchResultCount: action.searchResultCount,
                searchTerm: action.searchTerm,
                current_offset: action.current_offset,
                current_pagesize: action.current_pagesize,
                current_page: action.current_page
            };        
            case REQUEST_CATEGORIES:
                return {
                    ...state,
                    isLoading: true,
                    isError: false
                };
            case RECEIVE_CATEGORIES:
                return {
                    ...state,
                    isLoading: false,
                    isError: false,
                    categories: action.categories
                };           
            case REQUEST_PRODUCT_UPDATE:
                return {
                    ...state,
                    products: [],
                    isProductsLoading: true,
                    isError: false
                };      
            case RECEIVE_PRODUCT_UPDATE:
                return {
                    ...state,
                    products: action.products,
                    categoryids: action.categoryids,
                    product_count: action.product_count,
                    isProductsLoading: false,
                    isError: false
                };                                           
        default:
            return state;
    }
}
