import React from 'react';
import Header from "components/Header/Header.js";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";
import HeaderLogo from 'components/common/headerLogo';
import user from '../../_helpers/user.js';

const useStyles = makeStyles(styles);

export default function TopBanner(){
    const classes = useStyles();
    return(
        <Header
        color="info"
        fixed
        leftLinks={
            <List className={classes.list}>
                <ListItem className={classes.listsItem}>
                    <HeaderLogo />
                </ListItem>
            </List>
        }
        rightLinks={
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Link to={'/products'}>
                    <Button
                    className={classes.navLink}
                    color="transparent"
                    >Products
                    </Button>
                </Link>
            </ListItem>
            {/* <ListItem className={classes.listItem}>
              <Link to={'/customize'}>
                <Button
                  href="/customize"
                  className={classes.navLink}
                  color="transparent"
                >
                  Customize
                </Button>
              </Link>
            </ListItem>   */}
            <ListItem className={classes.listItem}>
              <Link to={'/how-it-works'}>
                <Button
                  href="/how-it-works"
                  className={classes.navLink}
                  color="transparent"
                 >
                  How It Works
                  </Button>
                </Link>
            </ListItem>
            {user !== null && user.isAdmin && 
              <ListItem className={classes.listItem}>
                <Link to='/admin'>
                  <Button
                    className={classes.navLink}
                    color="transparent"
                  >
                    Admin
                  </Button>
                </Link>
              </ListItem>                        
            }
            <ListItem className={classes.listItem}>
              {user === null && 
                <Button
                href="/login"
                className={classes.loginLink}
                color="gray"
                >
                  Login
                </Button>
              }
              {user !== null && 
                <Button
                href="/logout"
                className={classes.loginLink}
                color="gray"
                >
                  Logout
                </Button>
              }
            </ListItem>
          </List>
        }
      />
    )
}
