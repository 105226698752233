import React, { useEffect, useState } from 'react';
import {register, login} from '../_services/user.services';
import Card from '../components/card/Card';
import CardHeader from '../components/card/CardHeader';
import CardFooter from '../components/card/CardFooter';
import Button from "../components/CustomButtons/Button.js";
import CardBody from "../components/card/CardBody.js";
import CustomInput from "../components/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import GridContainer from "components/grid/GridContainer.js";
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import GridItem from "components/grid/GridItem.js";

const useStyles = makeStyles(styles);

export default function Register(props)
{

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function() {
      setCardAnimation("");
    }, 700);
    const classes = useStyles();
  
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const handleRegister = () =>
    {
        setEmailError(false);
        setFirstNameError(false);
        setLastNameError(false);
        setPasswordError(false);
        let error = false;
        if (email === undefined)
        {
            setEmailError(true);
            error = true;
        }
        if (firstName === undefined)
        {
            setFirstNameError(true);
            error = true;
        }
        if (lastName === undefined)
        {
            setLastNameError(true);
            error = true;
        }
        if (password === undefined)
        {
            setPasswordError(true);
            error = true;
        }
        if (error)
        {
            return;
        }

        (async () => {
            try{
                var response = await register(email, password, firstName, lastName);
                if (response)
                {
                    await login(email, password);
                    window.location.replace('/')
                }
            }catch{
                setLoginError(true);
            }
        })();
    }

    return (
        <div className="marginTop150">
            <Container maxWidth='xl'>
                <GridContainer justify="center">
                    <GridItem xs={9} sm={8} md={4} lg={3}>
                        <Card className={classes[cardAnimaton]}>
                            <form className={classes.form}>
                            <CardHeader color="primary" className={classes.cardHeader}>
                                <h4>Register</h4>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                labelText="First Name..."
                                id="firstname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "text",
                                    error: firstNameError,
                                    onChange: (e) => setFirstName(e.target.value),
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputIconsColor}>
                                        text_format
                                        </Icon>
                                    </InputAdornment>
                                    )
                                }}
                                />
                               <CustomInput
                                labelText="Last Name..."
                                id="lastname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "text",
                                    error: lastNameError,
                                    onChange: (e) => setLastName(e.target.value),
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputIconsColor}>
                                        text_format
                                        </Icon>
                                    </InputAdornment>
                                    )
                                }}
                                />                                
                                <CustomInput
                                labelText="Email..."
                                id="email"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "email",
                                    error: emailError,
                                    onChange: (e) => setEmail(e.target.value),
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Email className={classes.inputIconsColor} />
                                    </InputAdornment>
                                    )
                                }}
                                />
                                <CustomInput
                                labelText="Password"
                                id="pass"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "password",
                                    error: passwordError,
                                    onChange: (e) => setPassword(e.target.value),
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputIconsColor}>
                                        lock_outline
                                        </Icon>
                                    </InputAdornment>
                                    ),
                                    autoComplete: "off"
                                }}
                                />
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>
                                <Button simple color="primary" size="lg" onClick={handleRegister}>
                                Register
                                </Button>
                            </CardFooter>
                            {loginError && 
                                <CardFooter className={classes.cardFooter} style={{color: 'red'}}>
                                    Something went wrong
                                </CardFooter>
                            }                            
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Container>
       </div>
    )
}