import Swagger from 'swagger-client';

export const REQUEST_SESSION = 'REQUEST_SESSION';
export const RECEIVE_SESSION = 'RECEIVE_SESSION';

export const actionCreators = {
    requestSession: (productid) => async (dispatch, getState) => {
        dispatch({type: REQUEST_SESSION, sessionid: {} });
        const BASEURL = process.env.REACT_APP_API_URL;
        Swagger({
            url: BASEURL + '/swagger/v1/swagger.json',
            requestInterceptor: (req) => {
                if (localStorage.getItem('user') != null)
                {
                    req.headers["Authorization"] = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;
                }
                return req;
            }
        })
        .then(client => {
            try{
                return client.apis.Products.get_generatesession({productid: productid});
            }catch{

            }
            
        })
        .then(response => {
            return JSON.parse(response.text);
        })
        .then(response => {
            dispatch({type: RECEIVE_SESSION, sessionid: response.sessionID });
        });
    }
}

