//@flow
import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ProductListItem from './productListItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../_actions/actions';
import Pagination from '../../components/pagination/Pagination';
import GridContainer from 'components/grid/GridContainer';
import Loading from 'components/common/loading';
import Header from "components/Header/Header.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";

const myStyles = makeStyles({
    header:
    {
        marginTop: 15,
        width: '100%',
        padding: 10,
        marginLeft: '0px !important'
    }
})
const useStyles = makeStyles(styles);

function ProductListing(props)
{
    const classes = useStyles();
    const myClasses = myStyles();
    const [pages, setPages] = useState([]);

    useEffect(() => {
        props.requestProducts(props.current_offset, props.current_pagesize, props.current_page, props.current_sort, props.current_sort_dir);
    }, [])

    useEffect(() => {
        let count = 0;
        if (props.searchResultCount !== undefined)
        {
            count = props.searchResultCount;
        }else
        {
            count = props.product_count;
        }

        const _pages = [];
        _pages.push({text: 'PREV', onClick: () => changePage(props.current_page - 1)});
        let totalPages = Math.ceil(count/props.current_pagesize);
        let pageRangeAdded = false;
        if (props.current_page < 15){
            for (let i=1;i<=totalPages;i++)
            {
                if (((totalPages > 15 && (i <= 15 || i > totalPages - 3)) || (totalPages < 15)))
                {
                    _pages.push({text: i, active: i === props.current_page ? true : false, onClick: () => changePage(i)});    
                }else if (!pageRangeAdded){
                    _pages.push({text: '...', onClick: () => changePage(i)});
                    pageRangeAdded = true;
                }
            }
        }else if (props.current_page >= 15)
        {
            _pages.push({text: 1, onClick: () => changePage(1)});    
            _pages.push({text: '...', onClick: () => changePage(2)});
            for (let i=14;i<=totalPages;i++)
            {
                _pages.push({text: i, active: i === props.current_page ? true : false, onClick: () => changePage(i)});    
            }
        }
        _pages.push({text: 'NEXT', onClick: () => changePage(props.current_page + 1)});
        setPages(_pages);
    }, [props.current_page, props.current_pagesize, props.current_offset, props.product_count, props.searchResultCount, props.categoryids]);

    const changePage = (page) =>
    {
        let count = 0;
        if (props.searchResultCount !== undefined)
        {
            count = props.searchResultCount;
        }else
        {
            count = props.product_count;
        }

        let totalPages = Math.ceil(count/props.current_pagesize);
        if (page > totalPages)
        {
            page = totalPages;
            if (props.searchResultCount !== undefined)
            {
                props.requestSearchResults(props.searchTerm, props.current_offset, props.current_pagesize, page);
            }else{
                props.requestProducts(props.current_offset, props.current_pagesize, page, props.current_sort, props.current_sort_dir);
            }
        }else if (page < 1)
        {
            page = 1;
            if (props.searchResultCount !== undefined)
            {
                props.requestSearchResults(props.searchTerm, 0, props.current_pagesize, page);
            }else{
                props.requestProducts(0, props.current_pagesize, page, props.current_sort, props.current_sort_dir);    
            }
        }else{
            let offset = ((page * props.current_pagesize) - props.current_pagesize);
            if (props.searchResultCount !== undefined)
            {
                props.requestSearchResults(props.searchTerm, offset, props.current_pagesize, page);
            }else
            {
                props.requestProducts(offset, props.current_pagesize, page, props.current_sort, props.current_sort_dir);    
            }
        }
    }

    const changeView = (pagesize) =>
    {
        if (props.searchResultCount !== undefined)
        {
            props.requestSearchResults(props.searchTerm, props.current_offset, pagesize);
        }else{
            props.requestProducts(props.current_offset, pagesize, props.current_page, props.current_sort, props.current_sort_dir);
        }
        
    }

    const changeSort = (sort) =>
    {
        if (sort === 'Price (Lowest to Highest)')
        {
            props.requestProducts(0, props.current_pagesize, 1, '1', 'asc');
        }else if (sort === 'Price (Highest to Lowest)')
        {
            props.requestProducts(0, props.current_pagesize, 1, '1', 'desc');
        }else if (sort === 'Name (Ascending)')
        {
            props.requestProducts(0, props.current_pagesize, 1, '2', 'asc');
        }else if (sort === 'Name (Descending)'){
            props.requestProducts(0, props.current_pagesize, 1, '2', 'desc');
        }

    }

    return (
        <>
            <GridContainer justify='center' xs={12}>
                <div className={myClasses.header}>
                    <Header
                    brand="Browse Our Products"
                    color="gray"
                    rightLinks={
                        <List className={classes.list}>
                        {/* <ListItem className={classes.listItem}>
                            <Button
                            href="#pablo"
                            className={classes.navLink}
                            onClick={e => e.preventDefault()}
                            color="transparent"
                            >
                            Link
                            </Button>
                        </ListItem> */}
                        <ListItem className={classes.listItem}>
                            <CustomDropdown
                            buttonText="View"
                            hoverColor="info"
                            dropdownHeader="View Per Page"
                            onClick={changeView}
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent"
                            }}
                            dropdownList={[
                                { selected: props.current_pagesize === '4' ? true : false, text: "4" },
                                { selected: props.current_pagesize === '12' ? true : false, text: "12" },
                                { selected: props.current_pagesize === '20' ? true : false, text: "20" },
                                { selected: props.current_pagesize === '28' ? true : false, text: "28" },
                                { selected: props.current_pagesize === '36' ? true : false, text: "36" }
                            ]}
                            />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <CustomDropdown
                            buttonText="Sort"
                            hoverColor="info"
                            dropdownHeader="Sort by"
                            onClick={changeSort}
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent"
                            }}
                            dropdownList={[
                                { selected: props.current_sort === '1' && props.current_sort_dir === 'asc' ? true : false, text: 'Price (Lowest to Highest)'},
                                { selected: props.current_sort === '1' && props.current_sort_dir === 'desc' ? true : false, text: "Price (Highest to Lowest)" },
                                { divider: true },
                                { selected: props.current_sort === '2' && props.current_sort_dir === 'asc' ? true : false, text: "Name (Ascending)" },
                                { selected: props.current_sort === '2' && props.current_sort_dir === 'desc' ? true : false, text: "Name (Descending)" }
                            ]}
                            />
                        </ListItem>
                        </List>
                    }
                    />
                </div>
            </GridContainer>    
            <GridContainer justify='center' xs={12}>
                <GridContainer justify='center' xs={12}>
                    {(props.products.length > 0 || props.searchResults.length > 0) && 
                        <Pagination pages={pages} color='info' />
                    }
                </GridContainer>
                {props.loading && 
                    <>
                    <GridContainer justify='center' xs={12}>
                        <Loading loading={props.loading}></Loading>
                    </GridContainer>
                    </>
                }
                {!props.loading && 
                    <GridContainer className="container" xs={12}>
                        <GridContainer justify='center' xs={12}>
                        {props.products !== undefined && props.products.map((product) => 
                            <ProductListItem key={product.id} product={product} />
                        )}
                        {props.products.length === 0 && props.searchResults.length === 0 && !props.isProductsLoading &&
                            <h3>No results</h3>
                        }
                        </GridContainer>
                    </GridContainer>
                }
                {!props.loading && 
                    <div className="container">
                        {props.searchResults !== undefined && props.searchResults.map((searchResult) => 
                            <ProductListItem key={searchResult.id} product={searchResult} />
                        )}
                    </div>
                }                
                <GridContainer justify='center' xs={12}>
                    <div className="marginTop25">
                        {(props.products.length > 0 || props.searchResults.length > 0) && 
                            <Pagination pages={pages} color='info' />
                        }
                    </div>
                </GridContainer>
            </GridContainer>
        </>
    )
}

const mapStateToProps = state => {
    return {
        products: state.productsReducer.products,
        searchResults: state.productsReducer.searchResults,
        searchResultCount: state.productsReducer.searchResultCount,
        categoryids: state.productsReducer.categoryids,
        searchTerm: state.productsReducer.searchTerm,
        loading: state.productsReducer.isProductsLoading,
        product_count: state.productsReducer.product_count,
        current_page: state.productsReducer.current_page === undefined ? 1 : state.productsReducer.current_page,
        current_pagesize: state.productsReducer.current_pagesize === undefined ? '20' : state.productsReducer.current_pagesize,
        current_offset: state.productsReducer.current_offset === undefined ? 0 : state.productsReducer.current_offset,
        current_sort: state.productsReducer.current_sort === undefined ? '3' : state.productsReducer.current_sort,
        current_sort_dir: state.productsReducer.current_sort_dir === undefined ? 'asc' : state.productsReducer.current_sort_dir
    }
}

const mapDispatchToProps = (dispatch) => 
    bindActionCreators({...actionCreators}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);