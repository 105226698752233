import React from 'react';
import RingLoader from "react-spinners/RingLoader";

export default function Loading(props)
{
    return (
        <>
            <RingLoader loading={props.loading} color="#000000" />
        </>
    )
}