import React from 'react';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import ContactUsSection from '../home/sections/ContactUsSection';

const useStyles = makeStyles(styles);

export default function Contact(props)
{

    const classes = useStyles();
    
    return (
        <div className='marginTop70'>
            <GridContainer xs={10}>
                <ContactUsSection />
             </GridContainer>
        </div>
        )
}