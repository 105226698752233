import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      maxWidth: 300,
    },
    media: {
      height: 300,
      width: 300,
    },
    card: {
        margin: 15
    },
    title:
    {
        color: '#222000',
        fontSize: '1.3em',
        maxWidth: 260,
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '1.3em',
        height: '2.6em'
    },
    price: {
        color: 'orange',
    },
    links: {
        color: '#00acc1',
        fontWeight: 'bold',
        fontSize: '0.9em',
        "&:hover": {
            boxShadow:
              "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)",
            backgroundColor: "#00acc1",
            color: "#fff",
            content: 'buy'
        }
    },
    cardActions:{
        float: 'right'
    }

  });

export default function ProductListItem(props)
{
    const classes = useStyles();

    function customize(e)
    {
        return false;
    }
    const [selectedImage] = useState(props.product.images[0].imageUrl);

    return (
        <>

                <Card className={classes.card}>
                    <Link to={`/products/${props.product.url}`}>
                        <CardActionArea>
                            <CardMedia className={classes.media} image={"images/" + selectedImage} title={props.product.name} />
                            <CardContent>
                                <div className={classes.title}>
                                    {props.product.name}
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Link>
                    {/* <CardActions className={classes.cardActions} >
                        <Button size="small" className={classes.links} onClick={customize}>
                            Customize
                        </Button>
                        <Link to={`/products/${props.product.url}`}>
                            <Button size="small" className={classes.links}>
                                ${props.product.price}
                            </Button>
                        </Link>
                    </CardActions> */}
                </Card>
                {/* <div className="product">
                    <div>
                        <img src={"/images/" + selectedImage} className="productListingImage" alt={props.product.name} />
                    </div>
                    <div className="productThumbnails">
                        {props.product.images.map((image) => 
                            <div>
                                <img src={'images/' + image.imageUrl} alt="{image.imageUrl}" className="productThumbnail" onClick={() => handleImageClick(image)} />
                            </div>
                        )}
                    </div>
                    <div className="productDetails">
                        <div className="productPrice">${props.product.price}</div>
                        <div className="productName">{props.product.name}</div>
                    </div>
                </div> */}

        </>
    )
}