import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfoCard from '../popups/infoCard';
import { actionCreators } from '../../_actions/actions';

const useStyles = makeStyles({
    search:
    {
        width: 300,
        marginTop: 20,
        color: '#00acc1'
    },
    searchIcon:
    {
        color: '#00acc1',
        fontSize: '2em'
    },
    root: {
        maxWidth: 300,
    },
    price: {
        color: 'orange',
    },
    resultsText:{
        fontSize: '12px',
        color: '#9c27b0'
    }
});

function ProductsFilter(props)
{
    const [searchTerm, setSearchTerm] = useState();

    function search()
    {
        props.requestSearchResults(searchTerm);
    }

    const searchChange = (e) => {
        setSearchTerm(e.target.value);
    }
    
    const searchKeyPress = (e) => {
        if (e.keyCode === 13)
        {
            props.requestSearchResults(searchTerm);
        }
    }

    const classes = useStyles();
    return (
        <>
        <TextField
            id="outlined-search"
            label="Search"
            type="search"
            variant="outlined"
            className={classes.search}
            onChange={searchChange}
            onKeyDown={searchKeyPress}
            value={searchTerm}
            InputProps={{
                endAdornment: (
                    <SearchIcon className={classes.searchIcon} onClick={search}></SearchIcon>
                )
            }} />

            {props.searchResults.length > 0 && 
                <div className={classes.resultsText}>
                    There {props.searchResultCount === '1' ? 'is' : 'are'} {props.searchResultCount} {props.searchResultCount === '1' ? 'result' : 'results'}
                </div>
            }

            <div>
                <InfoCard key='productFilters' />
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        searchResults: state.productsReducer.searchResults,
        searchResultCount: state.productsReducer.searchResultCount,
        products: state.productsReducer.products
    }
}

const mapDispatchToProps = (dispatch) => 
    bindActionCreators({...actionCreators}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFilter);