import Swagger from 'swagger-client';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const REQUEST_ALL_PRODUCTS = 'REQUEST_ALL_PRODUCTS';
export const RECEIVE_ALL_PRODUCTS = 'RECEIVE_ALL_PRODUCTS';
export const RECEIVE_PRODUCT_COUNT = 'RECEIVE_PRODUCT_COUNT';
export const REQUEST_SEARCH_RESULTS = 'REQUEST_SEARCH_RESULTS';
export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const REQUEST_PRODUCT_UPDATE = 'REQUEST_PRODUCT_UPDATE';
export const RECEIVE_PRODUCT_UPDATE = 'RECEIEVE_PRODUCT_UPDATE';

export const actionCreators = {
    requestProducts: (offset, size, page, sort, dir) => async (dispatch, getState) => {
        dispatch({ type: REQUEST_PRODUCTS });
        const BASEURL = process.env.REACT_APP_API_URL;
        Swagger({
            url: BASEURL + '/swagger/v1/swagger.json',
            requestInterceptor: (req) => {
                if (localStorage.getItem('user') != null)
                {
                    req.headers["Authorization"] = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;
                }
                return req;
            }
        })
        .then(client => {
            console.log(client.apis);
            return client.apis.Products.get_products({offset: offset, size: size, sortby: sort, sort: dir, categoryids: getState().productsReducer.categoryids});
        })
        .then(response => {
            return JSON.parse(response.text);
        })
        .then(response => {
            dispatch({type: RECEIVE_PRODUCTS, products: response.products, current_offset: offset, current_pagesize: size, current_page: page, current_sort: sort, current_sort_dir: dir, product_count: response.productCount });
        });
    },
    updateProducts: (categories) => async (dispatch, getState) => {
        alert(categories.toString());
        dispatch({ type: REQUEST_PRODUCT_UPDATE });
        
        const BASEURL = process.env.REACT_APP_API_URL;

        Swagger(BASEURL + '/swagger/v1/swagger.json')
        .then(client => {
            return client.apis.Products.get_products({offset: getState().productsReducer.current_offset, size: getState().productsReducer.current_size, sortby: getState().productsReducer.current_sort, sort: getState().productsReducer.current_sort_direction, categoryids: categories.join() });
        })
        .then(response => {
            return JSON.parse(response.text);
        })
        .then(response => {
            dispatch({type: RECEIVE_PRODUCT_UPDATE, products: response.products, categoryids: categories, product_count: response.productCount });
        });
    },
    requestAllProducts: (offset, size) => async (dispatch, getState) => {
        dispatch({ type: REQUEST_PRODUCTS });

        const BASEURL = process.env.REACT_APP_API_URL;

        Swagger(BASEURL + '/swagger/v1/swagger.json')
        .then(client => {
            return client.apis.Products.get_products_all();
        })
        .then(response => {
            return JSON.parse(response.text);
        })
        .then(response => {
            dispatch({type: RECEIVE_ALL_PRODUCTS, products: response, product_count: response.length });
        });
    },
    requestProduct: (url) => async (dispatch, getState) => {
        dispatch({ type: REQUEST_PRODUCT });

        if (getState().productsReducer.products.filter(x => x.url === url).length > 0)
        {
            let product = getState().productsReducer.products.filter(x => x.url === url)[0];
            dispatch({type: RECEIVE_PRODUCT, product: product});
        }else{
            const BASEURL = process.env.REACT_APP_API_URL;

            Swagger(BASEURL + '/swagger/v1/swagger.json')
            .then(client => {
                return client.apis.Products.get_products__url_({url: url});
            })
            .then(response => {
                return JSON.parse(response.text);
            })
            .then(response => {
                dispatch({type: RECEIVE_PRODUCT, product: response });
            });
        }
    },
    requestSearchResults: (searchTerm, offset, size, page) => async (dispatch, getState) => {
        dispatch({ type: REQUEST_SEARCH_RESULTS, searchTerm: searchTerm });

        const BASEURL = process.env.REACT_APP_API_URL;

        Swagger(BASEURL + '/swagger/v1/swagger.json')
        .then(client => {
            return client.apis.Search.get_search({searchTerm: searchTerm, offset: offset, size: size});
        })
        .then(response => {
            return JSON.parse(response.text);
        })
        .then(response => {
            dispatch({type: RECEIVE_SEARCH_RESULTS, searchResults: response.products, searchResultCount: response.oDataCount, searchTerm: searchTerm, current_offset: offset, current_pagesize: size, current_page: page });
        });
    },
    requestCategories: () => async (dispatch, getState) => {
        dispatch({ type: REQUEST_CATEGORIES });

        const BASEURL = process.env.REACT_APP_API_URL;

        Swagger(BASEURL + '/swagger/v1/swagger.json')
        .then(client => {
            console.log(client.apis);
            return client.apis.Products.get_products_categories();
        })
        .then(response => {
            return JSON.parse(response.text);
        })
        .then(response => {
            dispatch({type: RECEIVE_CATEGORIES, categories: response });
        });
    },    
}

