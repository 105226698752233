import Swagger from 'swagger-client';

export const REQUEST_UPDATE_PRODUCT = 'REQUEST_UPDATE_PRODUCT';
export const RECEIVE_UPDATE_PRODUCT = 'RECEIVE_UPDATE_PRODUCT';
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_ALL_PRODUCTS = 'RECEIVE_ALL_PRODUCTS';

export const actionCreators = {
    updateProduct: (product) => async (dispatch, getState) => {
        dispatch({type: REQUEST_UPDATE_PRODUCT, product: product, success: null });
        const BASEURL = process.env.REACT_APP_API_URL;
        Swagger({
            url: BASEURL + '/swagger/v1/swagger.json',
            requestInterceptor: (req) => {
                if (localStorage.getItem('user') != null)
                {
                    req.headers["Authorization"] = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;
                }
                return req;
            }
        })
        .then(client => {
            try{
                return client.apis.Products.put_products__id_({id: product.Id}, {requestBody: product});
            }catch(ex){
                console.log(ex);
            }
        })
        .then(response => {
            if (response.status === 200)
            {
                dispatch({type: RECEIVE_UPDATE_PRODUCT, success: response.success });
            }
        })
    },
    requestAllProducts: () => async (dispatch, getState) => {
        dispatch({ type: REQUEST_PRODUCTS });

        const BASEURL = process.env.REACT_APP_API_URL;

        Swagger(BASEURL + '/swagger/v1/swagger.json')
        .then(client => {
            return client.apis.Products.get_products_all_no_cache();
        })
        .then(response => {
            return JSON.parse(response.text);
        })
        .then(response => {
            dispatch({type: RECEIVE_ALL_PRODUCTS, products: response, product_count: response.length });
        });
    },

}

