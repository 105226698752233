import React from 'react';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function Shipping(props)
{

    const classes = useStyles();
    
    return (
        <div className='marginTop70'>
            <GridContainer xs={10}>
            <div className={classes.typo}>
                    <div className={classes.title}>
                        <h2>Shipping Policy</h2>
                    </div>

                    <p>Thank you for visiting and shopping at AsiaWorld Topper. Following are the terms and conditions that constitute our Shipping Policy.</p>

                    <div className={classes.title}>
                        <h2>Worldwide Shipping Policy</h2>
                    </div>

                    <div className={classes.title}>
                        <h3>Shipment processing time</h3>
                    </div>

                    <p>All orders are processed within 1-2 business days. Custom orders will take additional time to process. If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in shipment of your order, we will contact you via email or telephone.</p>

                    <div className={classes.title}>
                        <h3>Shipping rates & delivery estimates</h3>
                    </div>

                    <p>Shipping charges for your order will be calculated and displayed at checkout. Changing your shipping method after your order may result in new shipping charges.</p>

                    <div className={classes.title}>
                        <h3>Shipment method</h3>
                    </div>

                    <p>We ship anywhere in the world. We ship with whichever carrier will get your order to you in the time that you need it. We will work with you to ensure your order arrives to you in time.</p>
                    <p>Delivery delays can occasionally occur.</p>

                    <div className={classes.title}>
                        <h3>Shipment confirmation & Order tracking</h3>
                    </div>

                    <p>You will receive a Shipment Confirmation email once your order has shipped containing your tracking number(s). The tracking number will be active within 24 hours.</p>

                    <div className={classes.title}>
                        <h3>Customs, Duties and Taxes</h3>
                    </div>

                    <p>AsiaWorld Topper is not responsible for any customs and taxes applied to your order. All fees imposed during or after shipping are the responsibility of the customer (tariffs, taxes, etc.).</p>

                    <div className={classes.title}>
                        <h3>Damages</h3>
                    </div>

                    <p>AsiaWorld Topper is not liable for any products damaged or lost during shipping. If you received your order damaged, please contact the shipment carrier to file a claim. Please save all packaging materials and damaged goods before filing a claim.</p>

                    <div className={classes.title}>
                        <h3>Returns Policy</h3>
                    </div>

                    <p>Our ​Return & Refund Policy​ provides detailed information about options and procedures for returning your order.</p>
                    
                </div>
             </GridContainer>
        </div>
        )
}