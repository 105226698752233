import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../_actions/actions';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export function ProductCategories(props)
{
    const classes = useStyles();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [start, setStart] = useState(false);

    const handleToggle = (id) => {
        const currentIndex = selectedCategories.indexOf(id);
        const newChecked = [...selectedCategories];

        setStart(true);
        if (currentIndex === -1)
        {
            newChecked.push(id);
        }else{
            newChecked.splice(currentIndex, 1);
        }
        setSelectedCategories(newChecked);
    }

    useEffect(() => {
        if (start)
        {
            props.updateProducts(selectedCategories);
        }
    }, [selectedCategories]);

    useEffect(() => {
        props.requestCategories();
    }, []);

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={9}>
                <div>
                    {props.categories !== undefined && 
                        <div>
                            <h3>Categories</h3>
                            {props.categories.map((category) => (
                            <div
                            className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                            }
                            >
                            <FormControlLabel
                                key={category.id}
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => handleToggle(category.id)}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label={category.categoryName}
                            />
                            </div>                        
                            ))
                            }
                        </div>
                    }
                </div>
            </GridItem>
        </GridContainer>
    )
}

const mapStateToProps = state => {
    return {
        categories: state.productsReducer.categories
    }
}

const mapDispatchToProps = (dispatch) => 
    bindActionCreators({...actionCreators}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategories);