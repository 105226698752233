import React from 'react';
import HeaderLogo from 'components/common/headerLogo';
import ProductsListing from 'components/products/productsListing';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import ProductsFilter from 'components/products/productsFilter';
import ProductCategories from 'components/products/productCategories';

export default function Listing()
{
    return (
        <div className='marginTop70'>
            <GridContainer xs={12} justify='center'>
                <GridItem xs={3} justify='center'>
                    <div className='marginTop15 textCenter'>
                        <HeaderLogo />
                    </div>
                    <div className='marginTop15 textCenter'>
                        <ProductsFilter />
                    </div>
                    <div className="marginTop15 marginLeft25 textLeft">
                        {/* <ProductCategories /> */}
                    </div>
                </GridItem>
                <GridItem xs={9}>
                    <ProductsListing />
                </GridItem>
            </GridContainer>
        </div>
    )
    
}