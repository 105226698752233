import { createStore, applyMiddleware, compose } from 'redux';
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from '../_reducers/rootReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const history = createBrowserHistory();

const persistConfig = {
    key: 'primary',
    storage,
}
export default function configureStore()
{
// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
		enhancers.push(window.devToolsExtension());
	}

    const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

    const store = createStore(
        persistedReducer,
        compose(
            applyMiddleware(
                routerMiddleware(history),
                thunk
            ),
            ...enhancers
        )
    )

    return store;
}