import React from 'react';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function HowItWorks(props)
{

    const classes = useStyles();
    
    return (
        <div className='marginTop70'>
            <GridContainer xs={12}>
                <div className={classes.typo}>
                    <div className={classes.title}>
                        <h2>How It Works</h2>
                    </div>
                    <div className={classes.title}>
                        <h3>Animal Wedding Cake Topper</h3>
                    </div>
                    <p>
                        <ul>
                            <li>
                                Choose the topper you prefer
                            </li>
                            <li>
                                Choose the colour you want for the figure and base (if any)
                            </li>
                            <li>
                                Add a name/date plaque
                            </li>
                        </ul>
                    </p>

                    <div className={classes.title}>
                        <h3>Mini Wedding Cake Topper</h3>
                    </div>
                    <p>
                        <ul>
                            <li>
                                Choose the topper you prefer
                            </li>
                            <li>
                                Choose the colours of skin, hair, eyes, outfit, and base for your topper (if any)
                            </li>
                            <li>
                                You can swap the groom and bride from a different topper in the Mini Wedding Cake Topper collection (add your request into additional details)
                            </li>
                            <li>
                                Add a name/date plaque
                            </li>
                        </ul>
                    </p>

                    <div className={classes.title}>
                        <h3>Customized  Wedding Cake Topper</h3>
                    </div>
                    <div className={classes.title}>
                        <h5>Option 1 (Choose from our current toppers)</h5>
                    </div>
                    <p>
                        <ul>
                            <li>
                                Choose from our current toppers
                            </li>
                            <li>
                                Choose the colours of skin, hair, eyes, outfit, and base for your topper (if any)
                            </li>
                            <li>
                                Customize hairstyles, wedding outfit, bouquet as your uploaded photo (if any)
                            </li>
                            <li>
                                Add a name/date plaque
                            </li>
                        </ul>
                    </p>     
                    <div className={classes.title}>
                        <h5>Option 2 (Full Customization)</h5>
                    </div>
                    <p>
                        <ul>
                            <li>
                                Choose from our current toppers
                            </li>
                            <li>
                                Choose the colours of skin, hair, eyes, outfit, and base for your topper (if any)
                            </li>
                            <li>
                                Customize posture, hairstyles, wedding outfit, bouquet as your uploaded photo.
                            </li>
                            <li>
                                Add a name/date plaque
                            </li>
                        </ul>
                    </p>        

                    <div className={classes.title}>
                        <h3>Realistic Wedding Cake Topper (Detailed Face)</h3>
                    </div>
                    <div xs={12}>
                        <p>
                            <ul>
                                <li>
                                    Upload your portrait photo (close up photo of the front face, not the side photo)
                                </li>
                                <li>
                                    Choose the colours of skin, hair, eyes, and outfit for your topper.
                                </li>
                                <li>
                                    Customize posture, hairstyles, wedding outfit, bouquet as your uploaded photo.
                                </li>
                                <li>
                                    Add a name/date plaque
                                </li>
                            </ul>
                        </p>                             
                    </div>
                    <GridItem xs={12} sm={6}>
                        <p>
                        *Note - Every colour has so many different shades, soFcu please send us colour shade pictures or be as specific as possible when ordering any custom colours. Listed price is for a couple only. If you want to add a pet, vehicle, or accessories please contat us for more information
                        </p>
                    </GridItem>
                </div>
            </GridContainer>
        </div>
        )
}