import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../_actions/actions';
import SectionCarousel from '../sectionCarousel/sectionCarousel';
import GridContainer from '../grid/GridContainer';
import GridItem from '../grid/GridItem';
import CustomTabs from '../CustomTabs/CustomTabs';
import ProductPurchase from '../products/productPurchase';
import ProductCustomize from '../products/productCustomize';
import ProductOverview from '../products/productOverview';

export function ProductDetails(props){

    const productName = props.location.pathname.split("/")[2];

    const [images, setImages] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);

    const { requestProduct } = props;

    useEffect(() => {
        requestProduct(productName);
    },[requestProduct, productName]);

    useEffect(() => {
        if (props.product.images !== undefined)
        {
            setImages(props.product.images);
        }
        
    },[props.product]);

    const openCustomize = () => {
        setCurrentTab(2);
    }

    const openPurchase = () => {
        setCurrentTab(3);
    }

    const currentTabChanged = (value) => {
        setCurrentTab(value);
    }

    return (
        <>
        <GridContainer xs={12} justify='left'>
            <GridItem xs={12} sm={12} md={5} lg={5} xl={4}>
                <SectionCarousel images={images} className="productDetailsImages" />
            </GridItem>
            <GridItem xs={12} sm={12} md={7} lg={7} xl={7} justify='right'>
                <GridContainer justify='left'>
                    <GridItem xs={12} sm={12} md={12}>
                    <h3 className='productTitle'>
                        <medium>{props.product.name}</medium>
                    </h3>
                    <div className='productTabs'>
                        <CustomTabs
                            key='customTabs'
                            tabChanged={currentTabChanged}
                            plainTabs
                            headerColor="gray"
                            value={currentTab}
                            tabs={[
                            {
                                tabName: "Overview",
                                tabContent: (
                                    <ProductOverview product={props.product} openCustomize={openCustomize} openPurchase={openPurchase} />
                                )
                            },
                            {
                                tabName: "Details",
                                tabContent: (
                                    <div dangerouslySetInnerHTML={{
                                        __html: props.product.formattedDescription
                                    }}>
                                    </div>
                                )
                            },
                            // {
                            //     tabName: "Customize",
                            //     tabContent: (
                            //     <p className='textLeft'>
                            //         <ProductCustomize product={props.product} openPurchase={openPurchase} />
                            //     </p>
                            //     )
                            // },
                            // {
                            //     tabName: "Purchase",
                            //     tabContent: (
                            //     <p className='textLeft'>
                            //         <ProductPurchase product={props.product} />
                            //     </p>
                            //     )
                            // },
                            ]}
                        />
                    </div>
                    </GridItem>

                </GridContainer>
            </GridItem>
        </GridContainer>
        
        </>
    )
}

const mapStateToProps = state => {
    return {
        product: state.productsReducer.product,
    }
}

const mapDispatchToProps = (dispatch) => 
    bindActionCreators({...actionCreators}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);