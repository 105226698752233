export async function contact(name, email, message)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ name, email, message })
    }
    const BASEURL = process.env.REACT_APP_API_URL;

    return await fetch(BASEURL + '/contact', requestOptions)
        .then(handleResponse)
        .then(() => {
            return true;
        });
}

function handleResponse(response)
{
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if(!response.ok)
        {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    })
}