import {
    REQUEST_UPDATE_PRODUCT, RECEIVE_UPDATE_PRODUCT, REQUEST_PRODUCTS, RECEIVE_ALL_PRODUCTS
} from '../_actions/adminActions.js';

const initialState = {
    success: {}
}

export default function adminReducer(state, action)
{
    state = state || initialState;

     switch (action.type)
    {
        case REQUEST_UPDATE_PRODUCT:
            return {
                ...state,
                success: null
            };
        case RECEIVE_UPDATE_PRODUCT:
            return {
                ...state,
                sessionid: action.success
            };
        case REQUEST_PRODUCTS:
            return {
                ...state,
                products: [],
                isProductsLoading: true,
                isError: false
            };            
        case RECEIVE_ALL_PRODUCTS:
            return {
                ...state,
                products: action.products,
                isProductsLoading: false,
                isError: false
                };              
        default:
            return state;
    }
}
