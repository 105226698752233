import {
    STORE_SELECTIONS, GET_SELECTIONS
} from '../_actions/customizeActions.js';

const initialState = {
    selections: []
}

export default function customizeReducer(state, action)
{
    state = state || initialState;

     switch (action.type)
    {
        case STORE_SELECTIONS:
            return {
                ...state,
                selections: action.selections
            };
        case GET_SELECTIONS:
            return {
                ...state,
                selections: action.selections
            };
        default:
            return state;
    }
}
