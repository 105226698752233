import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from '../grid/GridContainer';
import GridItem from "components/grid/GridItem.js";
import Card from "components/card/Card.js";

import styles from "../../assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel(props) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };

  return (
    <div>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                {props.images.map(image => 
                <div>
                  <img src={'/images/' + image.imageUrl} alt="First slide" className="slick-image" />
                </div>
                )}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
