import React from 'react';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import ProductDetails from 'components/products/productDetails';
import HeaderLogo from '../../components/common/headerLogo';
import ProductsFilter from 'components/products/productsFilter';
import InfoCard from '../../components/popups/infoCard';


export default function Details(props)
{
    return (
        <div className='marginTop70'>
            <GridContainer xs={12} justify='center'>
                <GridItem xs={2} justify='center'>
                    <div className='marginTop15 textCenter'>
                        <HeaderLogo />
                    </div>
                    <div className='marginTop15 textCenter'>
                        <InfoCard key='productDetails' />
                    </div>
                </GridItem>
                <GridItem xs={10}>
                    <ProductDetails location={props.location} />
                </GridItem>
            </GridContainer>
        </div>
    )
    
}