import React, {useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {contact} from '../../../_services/contact.services';

// @material-ui/icons

// core components
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {

  const classes = useStyles();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [contactError, setContactError] = useState(false);
  const [contactMessage, setContactMessage] = useState();
  const [contactSuccess, setContactSuccess] = useState(false);
  
  function ValidateEmail(inputText)
  {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(inputText.match(mailformat))
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  const handleContact = () =>
  {
    if (name === '' || name === undefined)
    {
      setContactError(true);
      setContactMessage('Enter your name');
      return false;
    }
    if (email === '' || email === undefined || !ValidateEmail(email))
    {
      setContactError(true);
      setContactMessage('Enter a valid email address');
      return false;
    }
    if (message === '' || message === undefined)
    {
      setContactError(true);
      setContactMessage('Enter a message');
      return false;
    }

    setContactError(false)
    setContactMessage('');

      (async () => {
          try{
              var contactResult = await contact(name, email, message);
              if (contactResult)
              {
                setContactError(false);
                setContactSuccess(true);
                setContactMessage('We will respond to your message as soon as we can. Thanks!')
                setEmail('');
                setMessage('');
                setName('');
              }
          }catch{
              setContactError(true);
              setContactMessage('An error occured. Please contact asiaworldtopper@gmail.com');
          }
      })();
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contact Us</h2>
          <h4 className={classes.description}>
            If you have any questions or are unsure of how to proceed with your order, please feel free to contact us at anytime using the form below. We will respond within 24 hours of your inquiry.
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  inputProps={{
                    onChange: (e) => setName(e.target.value)
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  inputProps={{
                    onChange: (e) => setEmail(e.target.value)
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  onChange: (e) => setMessage(e.target.value),
                  multiline: true,
                  rows: 5
                }}
              />
              {contactError &&
                <GridItem xs={12} sm={12} md={12} justify="left">
                  <div className="messageContainer errorMessage">
                    {contactMessage}
                  </div>
                </GridItem>
              }
              {contactSuccess &&
                <GridItem xs={12} sm={12} md={12} justify="left">
                  <div className="messageContainer successMessage">
                    {contactMessage}
                  </div>
                </GridItem>
              }              
              <GridItem xs={12} sm={12} md={12}>
                <Button color="gray" onClick={handleContact}>Send Message</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
